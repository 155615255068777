import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { Observable } from "rxjs";

@Injectable()
export class ModuleService {
    constructor(private _http: HttpService) { }
    getQuestions(): Observable<any> {
        return this._http.makeGetWithoutAuth();
    }

    contact(data): Observable<any> {

        return this._http.makePostWithoutAuth(data, 'email/send');
    }
}