import { Injectable } from '@angular/core';
// import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

@Injectable()
export class Constants {
    // ERROR MESSAGES
    public static API_ERROR = 'Sorry, Something went wrong, Please try again.';
    public static API_URL: string = environment.API_URL; 
    public static SITE_KEY = environment.SITE_KEY;
    public static DATA_URL = environment.DATA_URL;
    public static EMAIL_PATTERN = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
    
    // Modal Popups
}