import { Component, OnInit, ViewChild } from '@angular/core';
import { ModuleService } from '../scripts/services/modules.service';
import { FormControl, FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Constants } from '../scripts/constants/constant';
import { NotificationsService } from 'angular2-notifications';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [ModuleService],
})

export class HomeComponent implements OnInit {
  questionList = [];
  slides = [];
  length = 0;
  captchaToken = '';
  emailPattern = Constants.EMAIL_PATTERN;
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 2000,
    lastOnBottom: true,
    clickToClose: true,
    showProgressBar: true,
    clickIconToClose: true,
    pauseOnHover: true,
    animate: 'fromRight'
  };
  myForm: FormGroup;
  submitted = false;
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, speed: 1500, vertical: true, arrow: false, fade: false, arrows: false, dots: false, infinite: false};
  @ViewChild('slickModal', {static: false}) slickModal: any;

  constructor(private moduleService: ModuleService, private fb: FormBuilder, public _notification: NotificationsService, private spinner: NgxSpinnerService) {
    this.myForm = this.fb.group({
      name: ['', Validators.required],
      message: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])],
      token: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.moduleService.getQuestions().subscribe(
      res => {
        this.questionList = res;
        if (this.questionList.length > 0) {
          this.addSlide(this.questionList[length]);
        }
      }
    );
  }

  loadQuestion(questionId): void {
    let ind = this.questionList.findIndex(item => {return item.id === questionId});
    if (ind > -1) {
      this.addSlide(this.questionList[ind]);
      setTimeout(() => {
        this.slickModal.slickNext();
      }, 0);
    }
  }

  restart(): void {
    this.slides.splice(1, this.slides.length);
    this.slickModal.slickGoTo(0);
  }

  contactUs(): void {
    const data = {
      id: -1,
      decision: []
    }
    this.addSlide(data);
      setTimeout(() => {
        this.slickModal.slickNext();
    }, 0);
  }

  submit(): void {
    this.submitted = true;
    if (this.myForm.valid) {
      let contactData = this.myForm.value;
      if (this.captchaToken !== undefined && this.captchaToken !== null && this.captchaToken !== '') {
        contactData.token = this.captchaToken;
        this.spinner.show();
        this.moduleService.contact(contactData).subscribe(
          res => {
            this.spinner.hide();
            this.myForm.reset();
            this.submitted = false;
            this._notification.success('Success', 'We have received your email and will reach you out sortly.');
          },
          err => {
            this.spinner.hide();
            this.myForm.reset();
            this._notification.error('Error', 'Sorry, Something went wrong. Please try again.');
          }
        )
      } 
    }
  }

  addSlide(data) {
    this.slides.push(data);
  }
  
  removeSlide() {
    this.slides.splice(0, 1);
  }
  
  slickInit(e) {
  }
  
  breakpoint(e) {
  }
  
  afterChange(e) {
    // this.removeSlide();
  }
  
  beforeChange(e) {
  }

  onVerify(token: string) {
    if (token !== '' && token !== undefined && token !== null) {
      this.captchaToken = token;
    }
  }

  onExpired(response: any) {
      // The verification expired.
  }

  onError(error: any) {
      // An error occured during the verification process.
  }
}