import { Injectable } from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import { Observable } from 'rxjs';
import { BehaviorSubject } from "rxjs";
import { Constants } from '../constants/constant';

@Injectable()
export class HttpService {
  networkStatus$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  dataURL: string = Constants.DATA_URL;
  baseAPIUrl: string = Constants.API_URL;

  constructor(private _http: HttpClient) {
  }
  makeGetWithoutAuth(): Observable<any> {
    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded').set('Accept', '*');
    return this._http.get(this.dataURL, {headers: httpHeaders});
  }

  makePostWithoutAuth(data, endPoint): Observable<any> {
    let httpHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json');
    let url = this.baseAPIUrl + endPoint;
    return this._http.post(url, data, {headers: httpHeaders});
  }
}

